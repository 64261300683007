<template>
  <div>
    <ejs-dialog
      ref="accountRegistrationPopup"
      header="거래처정보 상세"
      :animationSettings="animationSettings"
      :allowDragging="true"
      :showCloseIcon="true"
      width="832"
      :isModal="true"
      :cssClass="
        this.mode === 'update'
          ? $t('className.popup.productCodePopupModify')
          : $t('className.popup.productCodePopupAdd')
      "
      :close="onDialogClose"
    >
      <div class="window accountInformation">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">거래처정보 상세</div>
                    </div>
                    <div class="header-right">
                      <ul class="header-button">
                        <li class="delete" v-if="this.mode === 'update'">
                          <erp-button
                              button-div="DELETE"
                              :is-shortcut-button="true"
                              :ignore="isPopupOpened"
                              @click.native="onDeleteButtonClicked"
                          >
                            삭제
                          </erp-button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="section-body">
                    <article class="body-article">
                      <section class="article-section section-0101">
                        <div class="section-header">
                          <div class="header-left">
                            <div class="header-title">거래처 기본정보</div>
                          </div>
                        </div>
                        <div class="section-body">
                          <div class="body-data">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li class="field">
                                    <div class="title required">거래처명</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-text
                                              ref="bcncName"
                                              v-model="accountInfo.bcncName"
                                              @blur="onBcncNameBlur"
                                              @keydown.native="
                                                preventEnterEvent
                                              "
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title required">
                                      사업자 번호
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-maskedtextbox
                                              ref="bizNo"
                                              class="e-input"
                                              v-model="accountInfo.bizNo"
                                              mask="000-00-00000"
                                              @blur="onBizNoBlur"
                                              @keydown.native="
                                                preventEnterEvent
                                              "
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title required">대표자명</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-text
                                              ref="rprsntvName"
                                              v-model="
                                                accountInfo.rprsntvName
                                              "
                                              @keydown.native="
                                                preventEnterEvent
                                              "
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
<!--                                  <li class="field">-->
<!--                                    <div class="title required">법인번호</div>-->
<!--                                    <div class="content">-->
<!--                                      <ul class="row">-->
<!--                                        <li class="item form-group">-->
<!--                                          <div class="form">-->
<!--                                            <ejs-maskedtextbox-->
<!--                                              ref="cprNo"-->
<!--                                              class="e-input"-->
<!--                                              v-model="accountInfo.cprNo"-->
<!--                                              @blur="onCprNoBlur"-->
<!--                                              mask="000000-0000000"-->
<!--                                              @keydown.native="-->
<!--                                                preventEnterEvent-->
<!--                                              "-->
<!--                                              maxLength="50"-->
<!--                                            />-->
<!--                                          </div>-->
<!--                                        </li>-->
<!--                                      </ul>-->
<!--                                    </div>-->
<!--                                  </li>-->
                                  <li class="field">
                                    <div class="title required">
                                      거래처 구분
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                              ref="bcncDiv"
                                              v-model="accountInfo.bcncDiv"
                                              :dataSource="
                                                searchOptions.bcncDiv
                                              "
                                              :fields="commonCodeField"
                                              :allowFiltering="false"
                                              @keydown.native="
                                                preventEnterEvent
                                              "
                                              cssClass="body-data-dropdown"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">주민번호</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-maskedtextbox
                                              id="regNum"
                                              class="e-input"
                                              type="text"
                                              v-model="accountInfo.resNo"
                                              mask="000000-0000000"
                                              @keydown.native="
                                                preventEnterEvent
                                              "
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                      </section>
                      <section class="article-section section-0102">
                        <div class="section-header">
                          <div class="header-left">
                            <div class="header-title">거래처 부가정보</div>
                          </div>
                        </div>
                        <div class="section-body">
                          <div class="body-data">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li class="field">
                                    <div class="title">업태</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-text
                                              id="bizcnd"
                                              v-model="accountInfo.bizcnd"
                                              @keydown.native="
                                                preventEnterEvent
                                              "
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">업종</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-text
                                              id="induty"
                                              v-model="accountInfo.induty"
                                              @keydown.native="
                                                preventEnterEvent
                                              "
                                            />
                                          </div>
                                          <div class="validation">
                                            <div class="e-tip-content"></div>
                                            <div class="e-tip-top"></div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">주요생산품</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-text
                                              id="mainProduct"
                                              v-model="
                                                accountInfo.mainProduct
                                              "
                                              @keydown.native="
                                                preventEnterEvent
                                              "
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title required">사용여부</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <ul class="check">
                                            <li>
                                              <label>
                                                <input
                                                  type="radio"
                                                  id="useFlagTrue"
                                                  :value="true"
                                                  v-model="
                                                    accountInfo.useFlag
                                                  "
                                                  name="useFlag"
                                                />
                                                <i></i>
                                                <div class="label">사용</div>
                                              </label>
                                            </li>
                                            <li>
                                              <label>
                                                <input
                                                  type="radio"
                                                  id="useFlagFalse"
                                                  :value="false"
                                                  v-model="
                                                    accountInfo.useFlag
                                                  "
                                                  name="useFlag"
                                                />
                                                <i></i>
                                                <div class="label">
                                                  미사용
                                                </div>
                                              </label>
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">거래처 형태</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                              id="bcncStle"
                                              v-model="accountInfo.bcncStle"
                                              :dataSource="
                                                searchOptions.bcncStle
                                              "
                                              :fields="commonCodeField"
                                              :allowFiltering="false"
                                              @keydown.native="
                                                preventEnterEvent
                                              "
                                              cssClass="body-data-dropdown"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">거래처 부서</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                              id="bcncDept"
                                              v-model="accountInfo.bcncDept"
                                              :dataSource="
                                                searchOptions.bcncDept
                                              "
                                              :fields="commonCodeField"
                                              :allowFiltering="false"
                                              @keydown.native="
                                                preventEnterEvent
                                              "
                                              cssClass="body-data-dropdown"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                      </section>
                      <section class="article-section section-0103">
                        <div class="section-header">
                          <div class="header-left">
                            <div class="header-title">담당자/연락처</div>
                          </div>
                        </div>
                        <div class="section-body">
                          <div class="body-data">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li class="field">
                                    <div class="title">담당자</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-text
                                              id="mngr"
                                              @keydown.native="
                                                preventEnterEvent
                                              "
                                              v-model="accountInfo.mngr"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">이메일</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-text
                                              id="email"
                                              @keydown.native="
                                                preventEnterEvent
                                              "
                                              v-model="accountInfo.email"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">전화번호</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <component-telephone
                                              id="telNo"
                                              v-model="accountInfo.telNo"
                                              @keydown.native="
                                                preventEnterEvent
                                              "
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">팩스번호</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <component-telephone
                                              id="faxNo"
                                              v-model="accountInfo.faxNo"
                                              @keydown.native="
                                                preventEnterEvent
                                              "
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field address">
                                    <div class="title">주소</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group post">
                                          <div class="form">
                                            <input-text
                                              id="postNo"
                                              v-model="accountInfo.postNo"
                                              @keydown.native="
                                                preventEnterEvent
                                              "
                                            />
                                          </div>
                                        </li>
                                        <li class="item form-group button">
                                          <ul class="button">
                                            <li>
                                              <erp-button
                                                button-div="GET"
                                                @click.native="
                                                  onPostCodeClick()
                                                "
                                              >
                                                우편번호
                                              </erp-button>
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                      <ul class="row">
                                        <li class="item form-group input">
                                          <div class="form">
                                            <input-text
                                              id="addr1"
                                              v-model="accountInfo.addr1"
                                              @keydown.native="
                                                preventEnterEvent
                                              "
                                            />
                                          </div>
                                        </li>
                                        <li class="item form-group input">
                                          <div class="form">
                                            <input-text
                                              id="addr2"
                                              @keydown.native="
                                                preventEnterEvent
                                              "
                                              v-model="accountInfo.addr2"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field remarks">
                                    <div class="title">비고</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-textarea
                                              id="remarks"
                                              @keydown.native="
                                                preventEnterEvent
                                              "
                                              v-model="accountInfo.remarks"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                      </section>
                    </article>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-key-color="true"
                  :is-shortcut-button="true"
                  :ignore="isPopupOpened"
                  @click.native="onSaveButtonClicked"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="onCloseButtonClicked"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <daum-postcode-popup
      ref="daumPostcodePopup"
      v-if="isDaumPostcodePopupOpen"
      @popupClose="daumPostcodePopupClosed"
      @popupConfirmed="daumPostcodePopupConfirmed"
    />
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import {
  commonCodeGetComCodeDefaultValue,
  commonCodesGetCommonCode,
} from "@/utils/commonCodes";
import {
  addAccountInfo,
  editAccountInfo,
  deleteAccountInfo,
  checkDuplicate,
} from "@/api/accountRegistration";
import { validateFormRefs, ValidType } from "@/utils/formUtil";
import DaumPostcodePopup from "@/views/common/DaumPostcodePopup.vue";
import InputText from "@/components/common/text/InputText";
import InputTextarea from "@/components/common/text/InputTextarea";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ComponentTelephone from "@/components/ComponentTelephone";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "accountRegistrationPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    ComponentTelephone,
    InputTextarea,
    InputText,
    DaumPostcodePopup,
    ErpButton,
  },
  data() {
    return {
      mode: "create",
      commonCodeField: { text: "comName", value: "comCode" },
      isDaumPostcodePopupOpen: false,
      animationSettings: { effect: "None" },
      rules: {
        bcncName: {
          required: true,
        },
        bizNo: {
          required: true,
          type: ValidType.BIZ,
        },
        rprsntvName: {
          required: true,
        },
        cprNo: {
          required: true,
          type: ValidType.CPR,
        },
        bcncDiv: {
          required: true,
        },
      },
      searchOptions: {
        bcncDiv: commonCodesGetCommonCode("BCNC_DIV"),
        bcncStle: commonCodesGetCommonCode("BCNC_STLE"),
        bcncDept: commonCodesGetCommonCode("BCNC_DEPT"),
      },
      refresh: false,
      originAccountInfo: {},
      accountInfo: {
        accountId: null,
        bcncId: null, // number | null
        bcncName: null, // string | null
        bizNo: null, // string | null
        rprsntvName: null, // string | null
        cprNo: null, // string | null
        bcncDiv: commonCodeGetComCodeDefaultValue("BCNC_DIV"), // string | null
        resNo: null, // string | null
        bizcnd: null, // string | null
        induty: null, // string | null
        mainProduct: null, // string | null
        useFlag: true, // boolean | null
        bcncStle: commonCodeGetComCodeDefaultValue("BCNC_STLE"), // string | null
        bcncDept: commonCodeGetComCodeDefaultValue("BCNC_DEPT"), // string | null
        mngr: null, // string | null
        postNo: null, // string | null
        addr1: null, // string | null
        addr2: null, // string | null
        remarks: null, // string | null
        bankCode: null, // string | null
        acnutNo: null, // string | null
        email: null, // string | null
        telNo: null, // string | null
        faxNo: null, // string | null
      },
    };
  },
  computed: {
    isPopupOpened() {
      return this.isDaumPostcodePopupOpen;
    },
  },
  methods: {
    validateFormRefs,
    preventEnterEvent(event) {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    },
    openAccountRegistrationPopup() {
      this.refresh = false;
      this.$refs.accountRegistrationPopup.show();
    },
    closeAccountRegistrationPopup(refresh) {
      if (refresh) {
        this.refresh = true;
      }

      this.$refs.accountRegistrationPopup.hide();
    },
    initAccountInfo(accountInfo) {
      this.accountInfo = {
        ...getEmptyAccountInfoInstance(),
        ...Object.assign({}, accountInfo),
      };
      if (accountInfo) {
        this.originAccountInfo = JSON.parse(JSON.stringify(this.accountInfo));
      }
      this.mode = !accountInfo ? "create" : "update";
    },
    onDialogClose() {
      this.$emit("close", { refresh: this.refresh });
    },
    async onSaveButtonClicked() {
      if (!this.validateFormRefs(this.rules)) {
        return;
      }

      const task = this.mode === "create" ? addAccountInfo : editAccountInfo;
      await task(this.accountInfo);

      this.infoToast(this.$t("main.popupMessage.saved"));

      this.closeAccountRegistrationPopup(true);
    },
    async onDeleteButtonClicked() {
      if (!(await this.confirm(this.$t("main.popupMessage.confirmDelete")))) {
        return;
      }

      await deleteAccountInfo([this.accountInfo]);

      this.infoToast(this.$t("main.popupMessage.deleted"));

      this.closeAccountRegistrationPopup(true);
    },
    onCloseButtonClicked() {
      this.closeAccountRegistrationPopup(false);
    },
    async onBcncNameBlur({ value }) {
      if (!value || value === this.originAccountInfo.bcncName) {
        return;
      }

      const { value: duplicated } = await checkDuplicate(
          this.accountInfo.bcncId,
          { bcncName: value }
      );

      if (duplicated) {
        this.errorToast(this.$t("main.popupMessage.duplicatedRequiredData"));
        this.accountInfo.bcncName = "";
      }
    },
    async onBizNoBlur({ value }) {
      if (!value || value === this.originAccountInfo.bizNo) {
        return;
      }

      const { value: duplicated } = await checkDuplicate(
          this.accountInfo.bcncId,
          { bizNo: value }
      );

      if (duplicated) {
        this.errorToast(this.$t("main.popupMessage.duplicatedRequiredData"));
        this.accountInfo.bizNo = "";
      }
    },
    async onCprNoBlur({ value }) {
      if (!value || value === this.originAccountInfo.cprNo) {
        return;
      }

      const { value: duplicated } = await checkDuplicate(
          this.accountInfo.bcncId,
          { cprNo: value }
      );

      if (duplicated) {
        this.errorToast(this.$t("main.popupMessage.duplicatedRequiredData"));
        this.accountInfo.cprNo = "";
      }
    },

    onPostCodeClick() {
      this.onDaumPostcodePopupOpen();
    },
    onDaumPostcodePopupOpen() {
      this.isDaumPostcodePopupOpen = true;
      this.$nextTick(() => {
        this.$refs.daumPostcodePopup.showPopup();
      });
    },
    daumPostcodePopupClosed() {
      this.isDaumPostcodePopupOpen = false;
    },
    daumPostcodePopupConfirmed(data) {
      this.accountInfo.postNo = data.zonecode;
      this.accountInfo.addr1 = data.address;
      this.daumPostcodePopupClosed();
    },
  },
};

function getEmptyAccountInfoInstance() {
  return {
    bcncId: null, // number | null
    bcncName: null, // string | null
    bizNo: null, // string | null
    rprsntvName: null, // string | null
    cprNo: null, // string | null
    bcncDiv: null, // string | null
    resNo: null, // string | null
    bizcnd: null, // string | null
    induty: null, // string | null
    mainProduct: null, // string | null
    useFlag: true, // boolean | null
    bcncStle: null, // string | null
    bcncDept: null, // string | null
    mngr: null, // string | null
    postNo: null, // string | null
    addr1: null, // string | null
    addr2: null, // string | null
    remarks: null, // string | null
    bankCode: null, // string | null
    acnutNo: null, // string | null
    email: null, // string | null
    telNo: null, // string | null
    faxNo: null, // string | null
  };
}
</script>
