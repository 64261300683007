<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">거래처명/사업자등록번호</div>
            <ul class="content">
              <li class="item">
                <input-text
                  v-model="searchConditions.bcncNamebizNo"
                  @focus="onSearchValueClear"
                  @keydown.enter="onBcncNameBizNoKeyDown"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">거래처구분</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                  v-model="searchConditions.bcncDiv"
                  :dataSource="searchOptions.bcncDivOptions"
                  :fields="{ text: 'comName', value: 'comCode' }"
                  :allowFiltering="false"
                  cssClass="lookupDetail-condition-dropdown"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        v-model="searchConditions.useFlag"
                      />
                      <i></i>
                      <div class="label">사용여부</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              :ignore="isPopupOpened"
              @click.native="onViewButtonClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">거래처 목록</div>
              <div class="header-caption">[{{ count }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="add">
                  <erp-button
                      button-div="SAVE"
                      :ignore="isPopupOpened"
                      :is-icon-custom="true"
                      :is-custom-shortcut-button="true"
                      shortcut-key="accountRegistration.shortcuts.addAccount"
                      :shortcut="{key: 'F3'}"
                      @click.native="onCreateButtonClicked"
                  >
                    추가
                  </erp-button>
                </li>
                <li class="delete">
                  <erp-button
                      button-div="DELETE"
                      :is-shortcut-button="true"
                      :ignore="isPopupOpened"
                      @click.native="onDeleteButtonClicked"
                  >
                    삭제
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onClickExcel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                ref="grid"
                :allowResizing="true"
                :dataSource="accountInfoList"
                :allowPaging="true"
                :pageSettings="pageSettings"
                @headerCellInfo="headerCellInfo"
                @queryCellInfo="queryCellInfo"
                @recordClick="recordClick"
                :columns="accountColumn"
                :provides="grid"
                @actionComplete="gridActionComplete"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
    <account-registration-popup
      ref="accountRegistrationPopup"
      @close="onAccountRegistrationPopupClose"
      v-if="visibleAccountRegistrationPopup"
    />
  </div>
</template>

<style scoped>
body .appContent .body-article .section-body {overflow: hidden;border: none;}
</style>

<script>
import {
  Sort,
  Resize,
  Filter,
  Page,
  ExcelExport,
} from "@syncfusion/ej2-vue-grids";
import commonMixin from "@/views/layout/mixin/commonMixin";
import AccountRegistrationPopup from "../popup/AccountRegistrationPopup";
import {
  commonCodesGetCommonCode,
  commonCodesGetComName,
} from "@/utils/commonCodes";
import { numberWithCommas } from "@/utils/number";
import { getAccountInfo, deleteAccountInfo } from "@/api/accountRegistration";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import InputText from "@/components/common/text/InputText";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "AccountRegistration",
  components: {
    InputText,
    AccountRegistrationPopup,
    ejsGridWrapper,
    ErpButton,
  },
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  async created() {
    this.searchOptions.bcncDivOptions.unshift({ comCode: "", comName: "전체" });
    await this.getAccountInfoList();
  },
  data() {
    return {
      count: 0,
      grid: [Sort, Resize, Filter, Page, ExcelExport],
      pageSettings: { pageSize: 30 },
      accountColumn: [
        {
          field: "gridIdField",
          headerText: "gridIdField",
          isPrimaryKey: true,
          type: "number",
          visible: false,
        },
        { field: "bcncId", visible: false, isPrimaryKey: true },
        {
          field: "bcncName",
          allowEditing: "false",
          headerText: "거래처명",
          minWidth: "20",
          type: "string",
        },
        {
          field: "bizNo",
          allowEditing: "false",
          headerText: "사업자 등록번호",
          minWidth: "20",
          textAlign: "left",
          valueAccessor: "bizNoValueAccess",
          type: "string",
        },
        {
          field: "rprsntvName",
          allowEditing: false,
          headerText: "대표자명",
          minWidth: "20",
          textAlign: "left",
          type: "string",
        },
        {
          field: "bcncDiv",
          allowEditing: false,
          headerText: "거래처 구분",
          minWidth: "16",
          textAlign: "left",
          comCode: "BCNC_DIV",
          valueAccessor: this.valueAccessor,
          type: "string",
        },
        {
          width: 60,
          headerText: "사용",
          textAlign: "Center",
          type: "boolean",
          editType: "booleanedit",
          displayAsCheckBox: true,
          field: "useFlag",
          allowEditing: true,
        },
      ],
      visibleAccountRegistrationPopup: false,
      accountInfoList: [],
      searchConditions: {
        bcncNamebizNo: null, // 거래처명/사업자등록번호
        bcncDiv: "", // 거래처 구분
        useFlag: true, // 사용/미사용 선택여부
      },
      searchOptions: {
        bcncDivOptions: commonCodesGetCommonCode("BCNC_DIV", true),
      },
      accountInfoListLength: 0,
    };
  },
  computed: {
    isPopupOpened() {
      return this.visibleAccountRegistrationPopup;
    },
  },
  methods: {
    numberWithCommas,
    valueAccessor(field, data, column) {
      let code = data[field];
      let returnCode = null;
      if (code != null) {
        returnCode = commonCodesGetComName(column.comCode, code);
      }
      return returnCode;
    },
    bizNoValueAccess(field, data) {
      let bizNo = data[field];
      if (bizNo != null) {
        bizNo =
          bizNo.substring(0, 3) +
          "-" +
          bizNo.substring(3, 5) +
          "-" +
          bizNo.substring(5);
      } else {
        bizNo = "";
      }
      return bizNo;
    },
    headerCellInfo(args) {
      if (args.cell.column.field === "bcncName") {
        args.node.classList.add(this.$t("className.grid.clickArea"));
      }
    },
    queryCellInfo(args) {
      if (args.column.field === "bcncName") {
        this.closeAccountRegistrationPopup();
        args.cell.classList.add(this.$t("className.grid.clickArea"));
      }

      if (args.column && !args.data.useFlag) {
        args.cell.style.textDecoration = "line-through";
        args.cell.style.textDecorationColor = "red";
      }
    },
    recordClick(args) {
      this.closeAccountRegistrationPopup();
      if (args.column && args.column.field === "bcncName") {
        this.openUpdateAccountRegistrationPopup(args.rowData);
      }
    },
    async onBcncNameBizNoKeyDown() {
      await this.getAccountInfoList();
    },
    onSearchValueClear() {
      this.searchConditions.bcncNamebizNo = null;
    },
    openAccountRegistrationPopup(accountInfo) {
      this.visibleAccountRegistrationPopup = true;
      this.$nextTick(() => {
        const {
          openAccountRegistrationPopup,
          initAccountInfo,
        } = this.$refs.accountRegistrationPopup;
        if (accountInfo) {
          initAccountInfo(accountInfo);
        } else {
          initAccountInfo(null);
        }
        openAccountRegistrationPopup();
      });
    },
    closeAccountRegistrationPopup() {
      this.visibleAccountRegistrationPopup = false;
    },
    openCreateAccountRegistrationPopup() {
      this.openAccountRegistrationPopup(null);
    },
    openUpdateAccountRegistrationPopup(accountInfo) {
      this.openAccountRegistrationPopup(accountInfo);
    },
    onCreateButtonClicked() {
      this.closeAccountRegistrationPopup();
      this.openCreateAccountRegistrationPopup();
    },
    async onAccountRegistrationPopupClose({ refresh }) {
      if (refresh) {
        await this.getAccountInfoList();
      }

      this.closeAccountRegistrationPopup();
    },
    async onViewButtonClicked() {
      await this.getAccountInfoList();
    },
    async onDeleteButtonClicked() {
      let selectedRecords = this.$refs.grid.getSelectedRecords();
      if (selectedRecords.length === 0) {
        this.errorToast("삭제할 데이터를 선택해 주세요");
        return;
      }
      if (!(await this.confirm("삭제하시겠습니까?"))) {
        return;
      }
      await deleteAccountInfo(selectedRecords);
      await this.getAccountInfoList();
    },
    async getAccountInfoList() {
      const bcncNamebizNo =
        this.searchConditions.bcncNamebizNo === ""
          ? null
          : this.searchConditions.bcncNamebizNo;
      const bcncDiv =
        this.searchConditions.bcncDiv === ""
          ? null
          : this.searchConditions.bcncDiv;
      const useFlag = this.searchConditions.useFlag ? true : null;

      const response = await getAccountInfo(bcncNamebizNo, bcncDiv, useFlag);
      this.accountInfoList = response.value.tsBcncList.map(
          (item, index) => {
            return {
              ...item,
              _rid: index + 1,
            };
          }
      );

      this.accountInfoListLength = this.accountInfoList.length;
    },
    gridActionComplete() {
      this.count = numberWithCommas(this.$refs.grid?.getGridBatchCount() || 0);
    },
    onClickExcel() {
      this.$refs.grid.excelExport();
    },
  },
};
</script>
